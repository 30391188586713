/* eslint-disable */

import Swiper from "swiper/bundle";

class Slider {
	constructor() {
		this.init();
	}

	init() {
		this.initHeroSlider();

		Array.from(document.querySelectorAll(".featured-content")).forEach(
			(section) => {
				this.initFeaturedContentSlider(section);
				this.initFeaturedContentBoxedSlider(section);
				this.initFeaturedContentSliderBig(section);
			}
		);

		Array.from(document.querySelectorAll(".activities")).forEach((section) =>
			this.initActivitiesSlider(section)
		);

		Array.from(document.querySelectorAll(".slider")).forEach((section) =>
			this.initSliderSection(section)
		);

		Array.from(document.querySelectorAll(".customers-feedback")).forEach(
			(section) => this.initCustomersFeedbackSlider(section)
		);

		Array.from(document.querySelectorAll(".promotions")).forEach((section) =>
			this.initPromotionsSlider(section)
		);
	}

	initHeroSlider() {
		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(".hero .swiper-container", {
			// Optional parameters
			direction: "horizontal",
			loop: true,
			slidesPerView: 1,
			spaceBetween: 64,
			autoplay: true,
			delay: 3000,

			// Navigation arrows
			navigation: {
				nextEl: ".js-hero-swiper-next",
				prevEl: ".js-hero-swiper-prev",
			},
			on: {
				// LazyLoad swiper images after swiper initialization
				afterInit: (swiper) => {
					window.lazyLoading.init();
				},
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initFeaturedContentSlider(section) {
		const swiperContainer = section.querySelector(
			".col-dt-6 .swiper-container"
		);
		const swiperButtonNext = section.querySelector(
			".js-featured-content-slider-swiper-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-featured-content-slider-swiper-prev"
		);

		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 1.6,
					slidesOffsetAfter: 410,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initFeaturedContentBoxedSlider(section) {
		const swiperContainer = section.querySelector(".col-8 .swiper-container");
		const swiperButtonNext = section.querySelector(
			".js-featured-content-slider-swiper-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-featured-content-slider-swiper-prev"
		);

		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initFeaturedContentSliderBig(section) {
		const swiperContainer = section.querySelector(
			".tall-slider .swiper-container"
		);
		const swiperButtonNext = section.querySelector(
			".js-featured-content-tall-slider-swiper-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-featured-content-tall-slider-swiper-prev"
		);

		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 1.5,
					slidesOffsetAfter: 410,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initActivitiesSlider(section) {
		const swiperContainer = section.querySelector(".swiper-container");
		const swiperButtonNext = section.querySelector(
			".js-cards-slider-button-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-cards-slider-button-prev"
		);

		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,

			// Responsive
			breakpoints: {
				576: {
					width: 410,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initSliderSection(section) {
		const swiperContainer = section.querySelector(".swiper-container");
		const swiperButtonNext = section.querySelector(
			".js-featured-content-swiper-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-featured-content-swiper-prev"
		);

		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 30,

			// Responsive
			breakpoints: {
				992: {
					slidesPerView: 2.5,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initCustomersFeedbackSlider(section) {
		const swiperContainer = section.querySelector(".swiper-container");
		const swiperButtonNext = section.querySelector(
			".js-customers-feedback-swiper-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-customers-feedback-swiper-prev"
		);

		/* eslint-disable no-unused-vars, no-undef */
		new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			slidesPerView: 1,
			spaceBetween: 50,

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */
	}

	initPromotionsSlider(section) {
		const swiperContainer = section.querySelector(".swiper-container");
		const swiperButtonNext = section.querySelector(
			".js-promotions-button-next"
		);
		const swiperButtonPrev = section.querySelector(
			".js-promotions-button-prev"
		);

		const sliderReset = () => {
			sliderSection.destroy();
			this.initPromotionSlider(
				swiperContainer,
				swiperButtonNext,
				swiperButtonPrev
			);
			console.log("reset");
		};

		/* eslint-disable no-unused-vars, no-undef */
		const sliderSection = this.initPromotionSlider(
			swiperContainer,
			swiperButtonNext,
			swiperButtonPrev
		);
		/* eslint-enable no-unused-vars, no-undef */

		if (sliderSection && swiperContainer) {
			window.addEventListener("resize", window.throttle(sliderReset, 200));
		}
	}

	initPromotionSlider(swiperContainer, swiperButtonNext, swiperButtonPrev) {
		// Disable slider if slides number is less than four and only for desktop viewports.
		const checkEnabled = () => {
			const slidesNum = swiperContainer.dataset.slidesNum;
			const mq = window.matchMedia("(max-width: 1200px)");

			switch (Number(slidesNum)) {
				case 1:
					return false;
				case 2:
				case 3:
					if (mq.matches) {
						return true;
					}
					return false;
				default:
					return true;
			}
		};

		/* eslint-disable no-unused-vars, no-undef */
		const sliderSection = new Swiper(swiperContainer, {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			spaceBetween: 50,
			enabled: checkEnabled(),
			// Responsive
			breakpoints: {
				576: {
					width: 410,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: swiperButtonNext,
				prevEl: swiperButtonPrev,
			},
		});
		/* eslint-enable no-unused-vars, no-undef */

		return sliderSection;
	}
}

export default Slider;
