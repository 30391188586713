/* eslint-disable */

import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import { Catalan } from "flatpickr/dist/l10n/cat.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Pagination from "../components/pagination.js";
import ScrollMagic from "scrollmagic";

class Filters {
	constructor() {
		this.filtersButtons = document.getElementsByClassName("js-filters");

		if (this.filtersButtons[0]) {
			this.init();
		}
	}

	init() {
		const filterPosts = this.filterPosts;
		const removeElementsByClass = this.removeElementsByClass;

		this.urlParamsFilter(filterPosts, removeElementsByClass);

		Array.prototype.forEach.call(this.filtersButtons, function (filterButton) {
			const datePicker = document.getElementById("dateIn");

			if (datePicker) {
				const lang = datePicker.dataset.lang;
				let langLocale = "default";

				if (lang === "ca") {
					langLocale = Catalan;
				} else if (lang == "es") {
					langLocale = Spanish;
				} else if (lang == "fr") {
					langLocale = French;
				}

				flatpickr("#dateIn", {
					dateFormat: "d/m/Y",
					minDate: "today",
					disableMobile: true,
					plugins: [new rangePlugin({ input: "#dateOut" })],
					locale: langLocale,
					onChange: function (selectedDates) {
						if (selectedDates.length == 2) {
							const dp = document.getElementById("dateIn");
							const postType = dp.dataset.postType;
							const taxonomy = dp.dataset.taxonomy;
							const columns = dp.dataset.columns;
							const titleLength = dp.dataset.titleLength;
							const pagination = dp.dataset.pagination;
							const maxPosts = dp.dataset.maxPosts;
							const gridTerms = document.getElementById("terms");
							const activeTerms = gridTerms.querySelectorAll(
								".grid__term--active"
							);
							let term = "";

							// Implement for one term filter.
							if (activeTerms.length == 1) {
								term = activeTerms[0].children[0].dataset.term;
							}

							const dateIn = flatpickr.formatDate(selectedDates[0], "d-m-Y");
							const dateOut = flatpickr.formatDate(selectedDates[1], "d-m-Y");
							// Get one term to modify grid. Done it like this in order to not modifiy the original code.
							const el = document.querySelectorAll(".js-filters")[0];
							filterPosts(
								postType,
								taxonomy,
								term,
								dateIn,
								dateOut,
								titleLength,
								pagination,
								maxPosts,
								columns,
								el,
								removeElementsByClass
							);
						}
					},
				});
			}
			filterButton.addEventListener("click", function (event) {
				const postType = this.dataset.postType;
				const taxonomy = this.dataset.taxonomy;
				const columns = this.dataset.columns;
				const term = this.dataset.term;
				const titleLength = this.dataset.titleLength;
				const pagination = this.dataset.pagination;
				const maxPosts = this.dataset.maxPosts;
				const filterButtonParent = filterButton.parentNode;
				const gridTerms = filterButtonParent.parentNode;
				const activeTerms = gridTerms.querySelectorAll(".grid__term--active");
				const fp = document.querySelector("#dateIn")._flatpickr;
				let dateIn = "";
				let dateOut = "";

				if (fp) {
					dateIn = fp.selectedDates[0]
						? flatpickr.formatDate(fp.selectedDates[0], "d-m-Y")
						: "";
					dateOut = fp.selectedDates[1]
						? flatpickr.formatDate(fp.selectedDates[1], "d-m-Y")
						: "";
				}

				event.preventDefault();
				activeTerms.forEach(function (activeTerm) {
					activeTerm.classList.remove("grid__term--active");
				});
				filterButtonParent.classList.add("grid__term--active");
				filterPosts(
					postType,
					taxonomy,
					term,
					dateIn,
					dateOut,
					titleLength,
					pagination,
					maxPosts,
					columns,
					this,
					removeElementsByClass
				);
			});
		});
	}

	urlParamsFilter(filterPosts, removeElementsByClass) {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const dateIn = urlParams.get("datein");
		const dateOut = urlParams.get("dateout");

		if (dateIn && dateOut) {
			const filterButton = this.filtersButtons[0];
			const postType = filterButton.dataset.postType;
			const taxonomy = filterButton.dataset.taxonomy;
			const columns = filterButton.dataset.columns;
			const term = filterButton.dataset.term;
			const titleLength = filterButton.dataset.titleLength;
			const pagination = filterButton.dataset.pagination;
			const maxPosts = filterButton.dataset.maxPosts;

			filterPosts(
				postType,
				taxonomy,
				term,
				dateIn.toString(),
				dateOut.toString(),
				titleLength,
				pagination,
				maxPosts,
				columns,
				filterButton,
				removeElementsByClass
			);
		}
	}

	filterPosts(
		postType,
		taxonomy,
		term,
		dateIn,
		dateOut,
		titleLength,
		pagination,
		maxPosts,
		columns,
		element,
		removeElementsByClass
	) {
		const request = window.ajaxReq();
		let url = encodeURI(scripts_ajax_variables.ajax_url); // eslint-disable-line
		const data =
			"action=filter_posts" +
			"&post-type=" +
			postType +
			"&taxonomy=" +
			taxonomy +
			"&term=" +
			term +
			"&dateIn=" +
			dateIn +
			"&dateOut=" +
			dateOut +
			"&title-length=" +
			titleLength +
			"&pagination=" +
			pagination +
			"&max-posts=" +
			maxPosts +
			"&columns=" +
			columns +
			"&nonce=" +
			scripts_ajax_variables.nonce;

		request.open("POST", url, true); // set true for async, false for sync request
		request.setRequestHeader(
			"Content-type",
			"application/x-www-form-urlencoded"
		);
		request.send(data); // or null, if no parameters are passed

		// On success append data
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				try {
					const grid = element.closest(".grid");
					const grid_cards = grid.getElementsByClassName("js-grid-cards")[0];

					// Remove current data
					removeElementsByClass(grid_cards, "grid__card");

					// Append data
					grid_cards.innerHTML = request.responseText;

					// Reload load more button
					window.pagination = new Pagination();

					// Cards animation
					window.barba.setAnimationScenes(new ScrollMagic.Controller());

					// Trigger lazy loading
					window.lazyLoading.init();
				} catch (error) {
					console.log(error);
				}
			}
		};
	}

	removeElementsByClass(element, className) {
		const nopreviousResults = document.getElementsByClassName(
			".page-template-agenda__no-results"
		);
		const nopreviousResult = nopreviousResults[0];

		if (nopreviousResult) {
			var elements = element.getElementsByClassName(className);

			while (elements.length > 0) {
				elements[0].parentNode.removeChild(elements[0]);
			}
		}
	}
}

export default Filters;
