/* eslint-disable */
class ContactForm {
	constructor() {
		this.init();
	}

	init() {
		this.firstForm = document.getElementById("contact-form");
		this.firstFormPrivacyPolicy = document.getElementById("input-form");
		this.firstFormFclpNewsletter = document.getElementById("fclp-newsletter");
		this.firstFormSendButton =
			document.getElementsByClassName("contact-button")[0];
		this.closeNotificationButton =
			document.getElementById("close-notification");
		if (this.firstForm) {
			this.newForm();
		}
	}

	newForm() {
		// Load Re-captcha V3
		/*
    var script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LcfausUAAAAAJiAVHizfv20JcEwL3-pd1oc8JKH')
    document.getElementsByTagName('head')[0].appendChild(script) */

		// Make sure the privacy policy value is unchecked
		this.firstFormPrivacyPolicy.checked = false;
		this.firstFormPrivacyPolicy.value = "unchecked";

		this.firstFormFclpNewsletter.checked = false;
		this.firstFormFclpNewsletter.value = "unchecked";

		// Listen for privacy policy checkbox changes
		this.firstFormPrivacyPolicy.addEventListener(
			"change",
			this.privacyPolicyCheckboxToogle
		);

		// Listen for privacy policy checkbox changes
		this.firstFormFclpNewsletter.addEventListener(
			"change",
			this.fclpNewsletterCheckboxToogle
		);

		// Prevent form submit
		this.firstForm.addEventListener("submit", this.preventFormSubmit);

		// Form validations and submit
		this.firstFormSendButton.addEventListener("click", this.submitValidateForm);

		// Close notificacion banner
		this.closeNotificationButton.addEventListener(
			"click",
			this.closeNotificationBanner
		);

		// Enable submit button
		this.firstFormSendButton.disabled = false;
	}

	privacyPolicyCheckboxToogle(privacyPolicyCheckbox) {
		if (privacyPolicyCheckbox.srcElement.value === "unchecked") {
			privacyPolicyCheckbox.srcElement.value = "checked";
		} else {
			privacyPolicyCheckbox.srcElement.value = "unchecked";
		}
	}

	fclpNewsletterCheckboxToogle(fclpNewsletterCheckbox) {
		if (fclpNewsletterCheckbox.srcElement.value === "unchecked") {
			fclpNewsletterCheckbox.srcElement.value = "checked";
		} else {
			fclpNewsletterCheckbox.srcElement.value = "unchecked";
		}
	}

	preventFormSubmit(evt) {
		evt.preventDefault();
	}

	submitValidateForm(evt) {
		// Get the form parameters
		var action = "contact_form";
		var errorMessageContainer = document.getElementsByClassName(
			"form__error__message"
		)[0];
		var errorMessageValidation = document.getElementsByClassName(
			"form__error__message__validation"
		)[0];
		var errorMessageSuccess = document.getElementsByClassName(
			"form__error__message__success"
		)[0];
		var firstName = document.getElementsByName("first_name")[0];
		var lastName = document.getElementsByName("last_name")[0];
		var email = document.getElementsByName("email")[0];
		var message = document.getElementsByName("message")[0];
		var privacyPolicy = document.getElementsByName("privacy_policy")[0];
		var fclpNewsletter = document.getElementsByName("fclp_newsletter")[0];
		var form = document.getElementById("form");
		var count = form.dataset.count;
		var pageId = form.dataset.pageid;
		var lang = form.dataset.lang;
		let langLocale = "Anglés";
		if (lang === "ca") {
			langLocale = "Català";
		} else if (lang == "es") {
			langLocale = "Castellà";
		} else if (lang == "fr") {
			langLocale = "Francés";
		}

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");
		// Ajax form submit
		var xmlhttp = window.ajaxReq();
		var url = encodeURI(scripts_ajax_variables.ajax_url); // eslint-disable-line
		var params =
			"action=" +
			action +
			"&first_name=" +
			firstName.value +
			"&last_name=" +
			lastName.value +
			"&email=" +
			email.value +
			"&message=" +
			message.value +
			"&privacy_policy=" +
			privacyPolicy.value +
			"&fclp_newsletter=" +
			fclpNewsletter.value +
			"&lang=" +
			langLocale +
			"&count=" +
			count +
			"&page_id=" +
			pageId;
		/*  +  "&token=" +
      token*/ xmlhttp.open("POST", url, true); // set true for async, false for sync request
		xmlhttp.setRequestHeader(
			"Content-type",
			"application/x-www-form-urlencoded"
		);
		xmlhttp.send(params); // or null, if no parameters are passed

		// Validation
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
				try {
					var data = JSON.parse(xmlhttp.responseText);
					// Hide previous errors
					errorMessageContainer.classList.add("u-d-none");
					errorMessageValidation.classList.add("u-d-none");
					errorMessageSuccess.classList.add("u-d-none");
					firstName.classList.remove("show-error");
					lastName.classList.remove("show-error");
					email.classList.remove("show-error");
					message.classList.remove("show-error");
					privacyPolicy.classList.remove("show-error");

					if (data.validation === false) {
						errorMessageContainer.classList.remove("u-d-none");
						errorMessageValidation.classList.remove("u-d-none");

						// Show errors
						if (data.first_name) {
							firstName.classList.add("show-error");
						}
						if (data.last_name) {
							lastName.classList.add("show-error");
						}
						if (data.email) {
							email.classList.add("show-error");
						}
						if (data.message) {
							message.classList.add("show-error");
						}
						if (data.privacy_policy) {
							privacyPolicy.classList.add("show-error");
						}

						// Stop the animation
						evt.srcElement.classList.remove("validating");

						// Enable the button
						evt.srcElement.disabled = false;

						// Scroll to begin of form.
						document.getElementById("contact-form").scrollIntoView();
					} else if (data.success == false) {
						errorMessageContainer.classList.remove("u-d-none");
						errorMessageSuccess.classList.remove("u-d-none");
						// Stop the animation
						evt.srcElement.classList.remove("validating");

						// Enable the button
						evt.srcElement.disabled = false;

						// Scroll to begin of form.
						document.getElementById("contact-form").scrollIntoView();
					} else {
						// Stop the animation
						evt.srcElement.classList.remove("validating");

						// Add success class
						evt.srcElement.classList.add("success");

						// Clear the form
						setTimeout(() => {
							evt.srcElement.form.reset();
							privacyPolicy = document.getElementById("input-form");
							privacyPolicy.value = "unchecked";
							fclpNewsletter = document.getElementById("fclp-newsletter");
							fclpNewsletter.value = "unchecked";
							evt.srcElement.classList.remove("success");
						}, 2000);

						// Enable the button
						evt.srcElement.disabled = false;
					}

					// Enable and stop the submit button animation
					evt.srcElement.disabled = false;
					evt.srcElement.classList.remove("validating");
				} catch (error) {
					// Stop the animation
					evt.srcElement.classList.remove("validating");

					// Add success class
					evt.srcElement.classList.add("success");

					// Clear the form
					setTimeout(() => {
						evt.srcElement.form.reset();
						privacyPolicy.value = "unchecked";
						evt.srcElement.classList.remove("success");
					}, 2000);
				}
			}
		};

		// Recaptcha validation
		/*
    grecaptcha.ready(function () {
      // eslint-disable-line
      grecaptcha
        .execute("6LcfausUAAAAAJiAVHizfv20JcEwL3-pd1oc8JKH", { action: action })
        .then(function (token) {
          // eslint-disable-line
        });
    }); */
	}
}

export default ContactForm;
