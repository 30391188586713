const Header = () => {
	const header = document.getElementById("header");
	const headerHeight = header.offsetHeight + 60;
	let distanceToTop = document.documentElement.scrollTop;

	function toggleScrollBlock() {
		if (document.body.classList.contains("u-block-scroll")) {
			document.body.classList.remove("u-block-scroll");
		} else {
			document.body.classList.add("u-block-scroll");
		}
	}

	function toggleSubMenu(evt) {
		if (evt.srcElement.classList.contains("menu-item-has-children")) {
			evt.preventDefault();
			if (evt.srcElement.classList.contains("u-unfold")) {
				evt.srcElement.classList.remove("u-unfold");
			} else {
				evt.srcElement.classList.add("u-unfold");
			}
		}
	}

	// Menu button event listener
	const mobileMenuToogler = document.getElementById("mobile-menu-toogle");
	if (mobileMenuToogler) {
		mobileMenuToogler.addEventListener("click", toggleScrollBlock);
	}

	// Submenu buttons event listener
	const headerSubMenu = document.getElementsByClassName(
		"menu-item-has-children"
	);
	if (headerSubMenu) {
		for (let index = 0; index < headerSubMenu.length; index += 1) {
			headerSubMenu[index].addEventListener("click", toggleSubMenu);
		}
	}

	// Add/remove class if header has preheader
	window.checkPreheader();

	function callback() {
		const newDistanceToTop = document.documentElement.scrollTop;

		if (newDistanceToTop <= headerHeight) {
			header.classList.remove("scrolled", "scrolled--show");
		} else if (
			newDistanceToTop < distanceToTop &&
			newDistanceToTop > headerHeight
		) {
			header.classList.add("scrolled--show");
		} else {
			header.classList.add("scrolled");
			header.classList.remove("scrolled--show");
		}
		distanceToTop = newDistanceToTop;
	}

	// Scroll event listener
	window.addEventListener("scroll", window.throttle(callback, 200));

	if (distanceToTop > 0) {
		header.classList.add("scrolled");
	}

	// Set a CSS variable to fix Webkit-based mobile phones handling of vh units.
	// You can use it with the vh() SASS function.
	function setCSSvh() {
		document.documentElement.style.setProperty(
			"--vh",
			`${window.innerHeight / 100}px`
		);
	}
	window.addEventListener("resize", setCSSvh);
	window.addEventListener("orientationchange", setCSSvh);
	setCSSvh();
};

export default Header;
