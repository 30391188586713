/* eslint no-console: "off", new-cap: ["error", { "newIsCapExceptions": ["rangePlugin"] }] */

import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import { Catalan } from "flatpickr/dist/l10n/cat";
import { French } from "flatpickr/dist/l10n/fr";
import { Spanish } from "flatpickr/dist/l10n/es";

class Booking {
	constructor() {
		this.body = document.querySelector("body");
		this.isHome = this.body.classList.contains("home");
		this.isHotel = this.body.classList.contains("page-template-hotel");
		this.isRoom = this.body.classList.contains("page-template-rooms");
		this.isWhatToDo = this.body.classList.contains("page-template-what-to-do");

		// Remove the modal if already exists.
		this.modalBody = document.querySelector(".modal__body");

		if (this.modalBody) {
			this.modalBody.remove();
		}

		if (this.isHome || this.isHotel || this.isRoom || this.isWhatToDo) {
			// Variables.
			this.form = document.getElementById("booking-form");
			this.formModal = document.getElementById("booking-form-modal");
			// Booking modal at home.
			this.modal = document.querySelector(".booking__modal");
			this.modalHotel = document.getElementById("booking__hotel__modal");
			this.modalOpenBtn = document.querySelector(".booking__modal__open");
			this.modalHotelOpenBtn = document.querySelector(
				".booking__hotel__modal__open"
			);
			this.modalCloseBtn = document.querySelector(".booking__modal__close");
			this.submit = document.querySelector(".booking__submit");
			this.modalHotelsubmit = document.querySelector(
				".booking__hotel__modal__submit"
			);

			this.modalOverlay = document.createElement("div");
			this.modalOverlay.className = "booking__modal__overlay";

			// Move modal to the body to force z-index.
			if (this.modal && !this.modal.classList.contains("modal__body")) {
				this.modal.remove();
				this.modal.classList.add("modal__body");
				this.body.appendChild(this.modal);
			}

			if (
				this.modalHotel &&
				!this.modalHotel.classList.contains("modal__body")
			) {
				this.modalHotel.remove();
				this.modalHotel.classList.add("modal__body");
				this.body.appendChild(this.modal);
			}

			// Hotel and visit checbox.

			this.hotelCheckbox = document.getElementById("hotelformType");
			this.visitCheckbox = document.getElementById("visitformType");
			this.hotelCheckboxLabel = document.getElementById("hotelformTypeLabel");
			this.visitCheckboxLabel = document.getElementById("visitformTypeLabel");

			if (this.hotelCheckbox && this.visitCheckbox) {
				// Hotel checkbox
				this.hotelCheckbox.checked = true;

				// Visit checkbox
				this.visitCheckbox.checked = false;

				this.hotelCheckboxLabel.addEventListener("click", this.toggleAdults);
				this.visitCheckboxLabel.addEventListener("click", this.toggleAdults);
			}

			if (this.openModal) {
				this.openModal = this.openModal.bind(this);
			}

			if (this.closeModal) {
				this.closeModal = this.closeModal.bind(this);
			}

			if (this.handleSubmit) {
				this.handleSubmit = this.handleSubmit.bind(this);
			}
		}

		if (this.form || this.formModal) {
			this.init(
				bookingUrl, // eslint-disable-line
				hotelEndpointTemplateString, // eslint-disable-line
				hotelRoomEndpointTemplateString, // eslint-disable-line
				dateFormat // eslint-disable-line
			);
		}
	}

	init(
		bookingUrl,
		hotelEndpointTemplateString,
		hotelRoomEndpointTemplateString,
		dateFormat
	) {
		let bookingForm = null;
		let langLocale = "default";

		if (this.form) {
			bookingForm = this.form;
		} else if (this.formModal) {
			bookingForm = this.formModal;
		} else {
			console.error("No form found");
		}

		this.lang = bookingForm.dataset.lang;

		if (this.lang === "ca") {
			langLocale = Catalan;
		} else if (this.lang === "es") {
			langLocale = Spanish;
		} else if (this.lang === "fr") {
			langLocale = French;
		}

		this.setBookingUrl(bookingUrl + this.lang);
		this.setHotelEndpointTemplateString(hotelEndpointTemplateString);
		this.setHotelRoomEndpointTemplateString(hotelRoomEndpointTemplateString);
		this.setDateFormat(dateFormat);

		const mq = window.matchMedia("(min-width: 992px)");
		if (this.isHome || this.isWhatToDo) {
			if (mq.matches) {
				this.pickr = flatpickr("#dateIn", {
					dateFormat: "d/m/Y",
					minDate: "today",
					inline: true,
					disableMobile: true,
					showMonths: 2,
					appendTo: document.getElementById("booking-calendar"),
					plugins: [new rangePlugin({ input: "#dateOut" })],
					locale: langLocale,
				});
			} else {
				this.pickr = flatpickr("#dateIn", {
					dateFormat: "d/m/Y",
					minDate: "today",
					inline: true,
					disableMobile: true,
					showMonths: 1,
					appendTo: document.getElementById("booking-calendar"),
					plugins: [new rangePlugin({ input: "#dateOut" })],
					locale: langLocale,
				});
			}
		} else if (this.isHotel || this.isRoom) {
			if (mq.matches) {
				this.pickr = flatpickr("#datein", {
					dateFormat: "d/m/Y",
					minDate: "today",
					plugins: [new rangePlugin({ input: "#dateout" })],
					locale: langLocale,
				});
			} else {
				this.pickr = flatpickr("#dateIn", {
					dateFormat: "d/m/Y",
					minDate: "today",
					inline: true,
					disableMobile: true,
					showMonths: 1,
					appendTo: document.getElementById("booking-calendar"),
					plugins: [new rangePlugin({ input: "#dateOut" })],
					locale: langLocale,
				});
			}
		}

		if (this.modalOpenBtn && this.modalCloseBtn && this.modalOverlay) {
			this.modalOpenBtn.addEventListener("click", this.openModal);
			this.modalCloseBtn.addEventListener("click", this.closeModal);
			this.modalOverlay.addEventListener("click", this.closeModal);
		}

		if (this.modalHotelOpenBtn && this.modalCloseBtn && this.modalOverlay) {
			this.modalHotelOpenBtn.addEventListener("click", this.openModal);
			this.modalCloseBtn.addEventListener("click", this.closeModal);
			this.modalOverlay.addEventListener("click", this.closeModal);
		}

		if (this.submit) {
			// Not able to overwrite remove submit eventListener added by the third-party plugin. So, we use click event. Result: not accesible without modifying plugin.
			this.submit.addEventListener("click", this.handleSubmit, false);
		}

		if (this.modalHotelsubmit) {
			this.modalHotelsubmit.addEventListener("click", this.handleSubmit, false);
			this.modalHotelsubmit.addEventListener(
				"submit",
				this.handleSubmit,
				false
			);
		}
	}

	/* eslint-disable class-methods-use-this */

	setBookingUrl(bookingUrl) {
		this.bookingUrl = bookingUrl;
	}

	setHotelEndpointTemplateString(hotelEndpointTemplateString) {
		this.hotelEndpointTemplateString = hotelEndpointTemplateString;
	}

	setHotelRoomEndpointTemplateString(hotelRoomEndpointTemplateString) {
		this.hotelRoomEndpointTemplateString = hotelRoomEndpointTemplateString;
	}

	setDateFormat(dateFormat) {
		this.dateFormat = dateFormat;
	}

	toggleAdults() {
		const hotelCheckbox = document.getElementById("hotelformType");
		const bookingAdults = document.getElementsByClassName(
			"booking__modal__adults"
		)[0];
		const hotelLabel = document.getElementById("hotelformTypeLabel");
		const visitLabel = document.getElementById("visitformTypeLabel");

		if (hotelCheckbox.checked) {
			hotelLabel.classList.add("checked");
			visitLabel.classList.remove("checked");
			bookingAdults.classList.remove("u-hidden");
		} else {
			hotelLabel.classList.remove("checked");
			visitLabel.classList.add("checked");
			bookingAdults.classList.add("u-hidden");
		}
	}

	/* eslint-ensable class-methods-use-this */

	openModal() {
		if (this.isWhatToDo) {
			document.querySelectorAll(".hero__booking")[0].style.zIndex = "8";
		}

		this.modalOverlay.classList.add("is-open");
		this.body.classList.add("o-hidden");
		this.modal.classList.add("is-open");
		document.body.appendChild(this.modalOverlay);
	}

	closeModal(e) {
		if (e !== undefined) {
			e.preventDefault();
		}
		this.modalOverlay.classList.remove("is-open");
		this.body.classList.remove("o-hidden");
		this.modal.classList.remove("is-open");
		document.body.removeChild(this.modalOverlay);
		if (this.isWhatToDo) {
			document.querySelectorAll(".hero__booking")[0].style.zIndex = "65";
		}
		this.pickr.clear();
	}

	handleSubmit(e) {
		e.preventDefault();

		const baseUri = this.bookingUrl;
		const bookingType = document.getElementsByName("bookingType");
		const dateIn = this.formatDate(this.pickr.selectedDates[0]);
		const dateOut = this.formatDate(this.pickr.selectedDates[1]);
		let endpoint = "";
		let closeModal = false;
		let endpointTarget = "_blank";
		let endpointTemplateString;
		let adults = "";

		// eslint-disable-next-line
		const fillTemplateString = function fillTemplate(
			templateString,
			templateVars
		) {
			// eslint-disable-next-line
			return new Function(`return \`${templateString}\`;`).call(templateVars);
		};

		if (document.getElementById("adults")) {
			adults = document.getElementById("adults").value;
		}

		if (this.isHome || this.isWhatToDo) {
			closeModal = true;

			if (bookingType.length > 0 && bookingType[1].checked) {
				endpointTemplateString = this.hotelEndpointTemplateString;
				const endpointTemplateVars = {
					baseUri: baseUri, // eslint-disable-line
					adults: adults, // eslint-disable-line
					dateIn: dateIn, // eslint-disable-line
					dateOut: dateOut, // eslint-disable-line
				};
				endpoint = fillTemplateString(
					endpointTemplateString,
					endpointTemplateVars
				);
			} else {
				endpoint = `${window.location.origin}/${this.lang}/agenda?datein=${dateIn}&dateout=${dateOut}#calendar`;
				endpointTarget = "_self";
			}
		} else if (this.isHotel || this.isRoom) {
			if (this.modalHotel.classList.contains("is-open")) {
				closeModal = true;
				adults = document.getElementById("adults-modal").value;
			}

			const endpointTemplateVars = {
				baseUri: baseUri, // eslint-disable-line
				adults: adults, // eslint-disable-line
				dateIn: dateIn, // eslint-disable-line
				dateOut: dateOut, // eslint-disable-line
			};

			if (this.isHotel) {
				endpointTemplateString = this.hotelEndpointTemplateString; // eslint-disable-line
			} else {
				endpointTemplateString = this.hotelRoomEndpointTemplateString;
			}

			endpoint = fillTemplateString(
				endpointTemplateString,
				endpointTemplateVars
			);
		}

		window.open(endpoint, endpointTarget);

		if (closeModal) {
			this.closeModal();
		}
	}

	formatDate(date) {
		const d = new Date(date);
		const ye = d.getFullYear();
		const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
		const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
		let formattedDate = date;

		if (
			(this.visitCheckboxLabel &&
				this.visitCheckboxLabel.classList.contains("checked") === true) ||
			this.dateFormat !== "reverse"
		) {
			formattedDate = `${da}-${mo}-${ye}`;
		} else {
			formattedDate = `${ye}-${mo}-${da}`;
		}

		return formattedDate;
	}
}

export default Booking;
