/* eslint-disable */
class Hero {
	constructor() {
		this.heroVideo = document.getElementsByClassName("hero__video")[0];
		if (this.heroVideo !== undefined) {
			this.init();
		}
	}

	// Detect mobile device
	detectMob() {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/webOS/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/iPod/i) ||
			navigator.userAgent.match(/BlackBerry/i) ||
			navigator.userAgent.match(/Windows Phone/i) ||
			screen.width <= 600
		) {
			return true;
		}
		return false;
	}

	init() {
		const isMobile = this.detectMob();
		if (isMobile === false) {
			this.heroVideo.load();
			this.heroVideo.play();
		}
	}
}

export default Hero;
