/* eslint-disable */
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import { Catalan } from "flatpickr/dist/l10n/cat.js";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";

class Newsletter {
	constructor() {
		this.init();
		this.firstForm = document.getElementsByClassName("company-form")[0];
		if (this.firstForm) {
			this.newForm();
		}
	}

	init() {
		// this.firstForm = document.getElementById("budget-form");
		this.firstForm = document.getElementsByClassName("newsletter-form")[0];
		this.firstFormPrivacyPolicy = document.getElementById("gdpr_17557");
		this.firstFormSendButton =
			document.getElementsByClassName("contact-button")[0];

		if (this.firstForm) {
			this.newForm();
		}
	}

	newForm() {
		var form = document.getElementById("mc-embedded-subscribe-form");

		if (form) {
			const lang = form.dataset.lang;
			let langLocale = "default";
			if (lang === "ca") {
				langLocale = Catalan;
			} else if (lang == "es") {
				langLocale = Spanish;
			} else if (lang == "fr") {
				langLocale = French;
			}

			flatpickr("#dateBirth", {
				dateFormat: "d/m/Y",
				disableMobile: true,
				locale: langLocale,
				onChange: function (selectedDates) {
					let dayInput = document.getElementById("mce-DAT_NAIX-day");
					let monthInput = document.getElementById("mce-DAT_NAIX-month");
					let yearInput = document.getElementById("mce-DAT_NAIX-year");
					// Save new dates in mailchimp hidden values.
					dayInput.value = flatpickr.formatDate(selectedDates[0], "d");
					monthInput.value = flatpickr.formatDate(selectedDates[0], "m");
					yearInput.value = flatpickr.formatDate(selectedDates[0], "Y");
				},
			});

			// Make sure the privacy policy value is unchecked
			this.firstFormPrivacyPolicy.checked = false;
			this.firstFormPrivacyPolicy.value = "unchecked";

			// Listen for privacy policy checkbox changes
			this.firstFormPrivacyPolicy.addEventListener(
				"change",
				this.privacyPolicyCheckboxToogle
			);

			// Form validations and submit
			this.firstFormSendButton.addEventListener(
				"click",
				this.submitValidateForm
			);
			this.firstFormSendButton.addEventListener(
				"submit",
				this.submitValidateForm
			);

			// Enable submit button
			this.firstFormSendButton.disabled = false;
		}
	}

	privacyPolicyCheckboxToogle(privacyPolicyCheckbox) {
		if (privacyPolicyCheckbox.srcElement.value === "unchecked") {
			privacyPolicyCheckbox.srcElement.value = "checked";
		} else {
			privacyPolicyCheckbox.srcElement.value = "unchecked";
		}
	}

	submitValidateForm(evt) {
		// Get the form parameters
		var errorMessageContainer = document.getElementsByClassName(
			"form__error__message"
		)[0];
		var errorMessageValidation = document.getElementsByClassName(
			"form__error__message__validation"
		)[0];
		var email = document.getElementsByName("EMAIL")[0];
		var firstName = document.getElementsByName("FNAME")[0];
		var lastName = document.getElementsByName("LNAME")[0];
		const fp = document.querySelector("#dateBirth")._flatpickr;
		var dateBirth = "";
		if (fp.selectedDates.length > 0) {
			var dateBirth = flatpickr.formatDate(fp.selectedDates[0], "d-m-Y");
		}
		var dateBirthEl = document.getElementById("dateBirth");
		var zipCode = document.getElementsByName("CP")[0];
		var privacyPolicy = document.getElementsByName("gdpr[17557]")[0];
		var form = document.getElementById("mc-embedded-subscribe-form");

		// Disable and animate the submit button
		evt.srcElement.disabled = true;
		evt.srcElement.classList.add("validating");

		// Hide previous errors
		errorMessageContainer.classList.add("u-d-none");
		errorMessageValidation.classList.add("u-d-none");
		firstName.classList.remove("show-error");
		lastName.classList.remove("show-error");
		email.classList.remove("show-error");
		zipCode.classList.remove("show-error");
		dateBirthEl.classList.remove("show-error");
		privacyPolicy.classList.remove("show-error");

		// Start validation.
		function validateEmail(email) {
			const re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		}

		var validation = true;

		if (!validateEmail(email.value)) {
			validation = false;
			email.classList.add("show-error");
		}

		if (firstName.value == "") {
			validation = false;
			firstName.classList.add("show-error");
		}

		if (lastName.value == "") {
			validation = false;
			lastName.classList.add("show-error");
		}

		if (dateBirth == "") {
			validation = false;
			dateBirthEl.classList.add("show-error");
		}

		if (zipCode.value == "") {
			validation = false;
			zipCode.classList.add("show-error");
		}

		if (!privacyPolicy.checked) {
			validation = false;
			privacyPolicy.classList.add("show-error");
		}

		if (!validation) {
			errorMessageContainer.classList.remove("u-d-none");
			errorMessageValidation.classList.remove("u-d-none");
			// Stop the animation
			evt.srcElement.classList.remove("validating");

			// Enable the button
			evt.srcElement.disabled = false;

			// Scroll to begin of form.
			document.getElementById("newsletter").scrollIntoView();
			evt.preventDefault();
		} else {
			// Stop the animation
			evt.srcElement.classList.remove("validating");

			// Add success class
			evt.srcElement.classList.add("success");

			// Clear the form
			setTimeout(() => {
				evt.srcElement.form.reset();
				let privacyPolicy = document.getElementById("gdpr_17557");

				privacyPolicy.value = "unchecked";
				evt.srcElement.classList.remove("success");
			}, 100);

			// Enable the button
			evt.srcElement.disabled = false;
		}
	}
}

export default Newsletter;
