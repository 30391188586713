/* eslint-disable */

import ScrollMagic from "scrollmagic";

class Cards {
	constructor() {
		this.customFeaturedLinksSections = document.getElementsByClassName(
			"featured-links--custom"
		);

		if (this.customFeaturedLinksSections[0]) {
			this.init();
		}
	}

	async init() {
		const loadMasonry = await this.loadMasonryJS();
		const initMasonryJS = this.initMasonryJS;
		const filterCards = this.filterCards;
		const customFeaturedLinksSections = this.customFeaturedLinksSections;

		Array.prototype.forEach.call(
			customFeaturedLinksSections,
			function (customFeaturedLinks) {
				const filters = customFeaturedLinks.getElementsByClassName(
					"featured-links__category"
				);
				const cardsCointainer = customFeaturedLinks.getElementsByClassName(
					"featured-links__cards-container"
				)[0];
				let cardsMasonry = false;

				if (loadMasonry) {
					cardsMasonry = initMasonryJS(cardsCointainer);
				}

				Array.prototype.forEach.call(filters, function (filter) {
					filter.addEventListener("click", function (event) {
						const postId = this.dataset.postId;
						const count = this.dataset.count;
						const categories = this.dataset.category;
						const activeCategories = customFeaturedLinks.getElementsByClassName(
							"featured-links__category--active"
						);

						// Remove previous current category class
						Array.prototype.forEach.call(
							activeCategories,
							function (activeCategory) {
								activeCategory.classList.remove(
									"featured-links__category--active"
								);
							}
						);

						// Apply current category class
						this.classList.add("featured-links__category--active");

						// Filter posts
						filterCards(
							postId,
							count,
							categories,
							cardsCointainer,
							cardsMasonry,
							initMasonryJS
						);
					});
				});
			}
		);
	}

	loadMasonryJS() {
		return new Promise((resolve, reject) => {
			if (window.masonry === undefined) {
				try {
					window.loadJS(
						"/wp-content/themes/mon-st-benet/js/vendor/masonry.min.js",
						() => {
							window.masonry = true;
							resolve(true);
						}
					);
				} catch (error) {
					reject(error);
				}
			} else {
				resolve(true);
			}
		});
	}

	initMasonryJS(cardsCointainer) {
		const masonry = new Masonry(cardsCointainer, {
			itemSelector: ".card",
			horizontalOrder: true,
			transitionDuration: 0,
		});

		return masonry;
	}

	filterCards(
		postId,
		count,
		categories,
		cardsCointainer,
		cardsMasonry,
		initMasonryJS
	) {
		const request = window.ajaxReq();
		let url = encodeURI(scripts_ajax_variables.ajax_url); // eslint-disable-line
		const data =
			"action=filter_cards_section" +
			"&post-id=" +
			postId +
			"&count=" +
			count +
			"&category=" +
			categories +
			"&nonce=" +
			scripts_ajax_variables.nonce;

		request.open("POST", url, true); // set true for async, false for sync request
		request.setRequestHeader(
			"Content-type",
			"application/x-www-form-urlencoded"
		);
		request.send(data); // or null, if no parameters are passed

		// On success append data
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				try {
					// Append data
					cardsCointainer.innerHTML = request.responseText;

					// Cards animation
					window.barba.setAnimationScenes(new ScrollMagic.Controller());

					// Trigger lazy loading
					window.lazyLoading.init();

					// Refresh Masonry
					setTimeout(() => {
						if (cardsMasonry !== false) {
							cardsMasonry.destroy();
							initMasonryJS(cardsCointainer);
						}
					}, 500);
				} catch (error) {
					console.log(error);
				}
			}
		};
	}
}

export default Cards;
