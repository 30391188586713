/* eslint-disable */

import barba from "@barba/core";
import ScrollMagic from "scrollmagic";
import Swiper from "swiper/bundle";
class Barba {
	constructor() {
		this.initBarba();
	}

	initBarba() {
		const htmlDocument = document.getElementsByTagName("html")[0];
		window.barbaLibrary = barba;
		window.barbaLibrary.init({
			debug: false,
			cacheIgnore: false,
			prefetchIgnore: false,
			timeout: 5000,
			transitions: [
				{
					name: "default",
					beforeLeave({ current }) {
						// Block scroll
						document.body.classList.add("u-mobile-block-scroll");

						// Remove fadein class from previous transitions
						current.container.classList.remove("barba-container--fadein");
					},
					leave({ current }) {
						// Hide current view
						current.container.classList.toggle("barba-container--fadeout");
					},
					beforeEnter() {
						// Scroll to top
						htmlDocument.style.scrollBehavior = "auto";
						htmlDocument.scrollIntoView({
							block: "start",
							behavior: "auto",
						});
					},
					enter({ current, next }) {
						// Show new view
						next.container.classList.toggle("barba-container--fadein");
						setTimeout(() => {
							next.container.classList.remove("barba-container--fadeout");
						}, 400);

						// Unlock scroll
						htmlDocument.style.scrollBehavior = "smooth";
						document.body.classList.remove("u-mobile-block-scroll"); // Remove scroll block
					},
				},
			],
			prevent: ({ el }) => {
				const ignoreClasses = ["ab-item", "another-class-here"]; // Additional (besides .js-barba-prevent) ignore links with these classes (ab-item is for wp admin toolbar links)
				if (/.pdf/.test(el.href.toLowerCase())) {
					return true;
				}
				if (el.classList && el.classList.contains("js-barba-prevent")) {
					return true;
				}
				if (el.dataset && el.dataset.lightbox) {
					return true;
				}
				for (let i = 0; i < ignoreClasses.length; i++) {
					if (el.classList.contains(ignoreClasses[i])) {
						return true;
					}
				}
			},
			requestError: (trigger, action, url, response) => {
				console.log("REQUEST ERROR");
				console.log(url);
				console.log(response);
			},
		});
		// Global enter hook
		window.barbaLibrary.hooks.enter(({ current, next, trigger }) => {
			window.requestAnimationFrame(() => {
				this.setBodyClasses(next.container);
				this.updatePreheader(next.container);
				this.setTranslationLinks(next.container);
				this.mobileMenu();
				this.updateCurrentHighlightedMenu(next.html);
				this.fixWpAdminBar();
				this.budgetForm();
				this.promotionsSlider();
				window.modulesLoader.loadModules();
				window.lazyLoading.loadImages();

				// Add/remove class if header has preheader
				window.checkPreheader();

				// Google Analytics
				if (typeof window.ga === "function") {
					window.ga("send", "pageview", window.location.pathname);
				}
				// Animations
				setTimeout(() => {
					this.setAnimationScenes(new ScrollMagic.Controller());
				}, 600);
			});
		});
	}

	setBodyClasses(e) {
		const skipClasses = [""]; // Add the classes you want to skip
		const currentBodyClasses = document.querySelector("body").classList;
		const newBodyClasses = e.querySelector("#body-classes").value;
		const splittedNewBodyClasses = newBodyClasses.split(" ");
		for (var i = 0; i < currentBodyClasses.length; i += 1) {
			if (newBodyClasses.indexOf(currentBodyClasses[i]) > -1) {
				skipClasses.push(currentBodyClasses[i]);
			}
		}
		Array.from(currentBodyClasses).forEach((bodyClass) => {
			// Don't remove class if it exists in skipClasses
			if (!skipClasses.includes(bodyClass)) {
				currentBodyClasses.remove(bodyClass);
			}
		});
		for (let s = 0; s < splittedNewBodyClasses.length; s++) {
			document.querySelector("body").classList.add(splittedNewBodyClasses[s]);
		}
	}

	updatePreheader(e) {
		const header = document.getElementsByClassName("header")[0];
		const currentPreheader = document.getElementsByClassName("preheader")[0];
		const newPreheaderContent = e.querySelector("#preheader").value;

		if (
			currentPreheader === undefined &&
			newPreheaderContent.toString().length > 1 &&
			newPreheaderContent !== "" &&
			newPreheaderContent !== undefined &&
			newPreheaderContent !== null
		) {
			let newPreheaderContainer = document.createElement("div");
			newPreheaderContainer.innerHTML = newPreheaderContent;
			let newPreheader =
				newPreheaderContainer.getElementsByClassName("preheader")[0];
			header.insertBefore(newPreheader, header.childNodes[0]);
		} else if (
			newPreheaderContent.toString().length > 1 &&
			newPreheaderContent !== "" &&
			newPreheaderContent !== undefined &&
			newPreheaderContent !== null
		) {
			currentPreheader.innerHTML = newPreheaderContent;
		} else if (currentPreheader !== undefined) {
			currentPreheader.remove();
		}
	}

	setTranslationLinks(e) {
		"use strict";
		const linksData = e.getAttribute("data-translation-links");
		if (linksData) {
			const links = JSON.parse(linksData);
			if (links.constructor === Array && links.length > 0) {
				for (let s = 0; s < links.length; s++) {
					const li = document.querySelector(
						".header__languages ul li:nth-child(" + (s + 1) + ") a"
					);
					if (li !== null) {
						li.setAttribute("href", links[s]);
					}
				}
			}
		}
	}

	mobileMenu() {
		const mobileMenuToogler = document.getElementById("mobile-menu-toogle");
		if (mobileMenuToogler) {
			mobileMenuToogler.checked = false;
		}
	}

	/**
	 * Parses the DOM structure of the BarbaJS fetch, and applies same classes on the header in DOM.
	 * @param {String} htmlString - The new page html in string format from barbaJs
	 */
	updateCurrentHighlightedMenu(htmlString) {
		const html = new window.DOMParser().parseFromString(
			htmlString,
			"text/html"
		);
		if ("body" in html === false || html.body.childNodes.length < 1) {
			// Why the length check?
			return;
		}
		// First remove current classes from all menu items and sub menus.
		for (const el of Array.from(document.querySelectorAll(".menu-item"))) {
			el.classList.remove("current-menu-item");
			el.classList.remove("current-menu-parent");
			el.classList.remove("current-menu-ancestor");
		}
		// Get the new current menu items and parents and set the appropiate classes.
		let newEl;
		for (const el of Array.from(html.querySelectorAll(".current-menu-item"))) {
			if (el.id) {
				newEl = document.querySelector(`#${el.id}`);
				if (newEl !== null) {
					newEl.classList.add("current-menu-item");
				}
			}
		}
		for (const el of Array.from(
			html.querySelectorAll(".current-menu-parent")
		)) {
			if (el.id) {
				newEl = document.querySelector(`#${el.id}`);
				if (newEl !== null) {
					newEl.classList.add("current-menu-parent");
				}
			}
		}
		for (const el of Array.from(
			html.querySelectorAll(".current-menu-ancestor")
		)) {
			if (el.id) {
				newEl = document.querySelector(`#${el.id}`);
				if (newEl !== null) {
					newEl.classList.add("current-menu-ancestor");
				}
			}
		}
	}

	promotionsSlider() {
		// For promotions slider disable slider if slides number is less than four and only for desktop viewports.
		const checkEnabled = () => {
			const slider = document.querySelector(".promotions .swiper-container");
			if (slider) {
				const slidesNum = slider.dataset.slidesNum;
				const mq = window.matchMedia("(max-width: 1200px)");
				switch (Number(slidesNum)) {
					case 1:
						return false;
					case 2:
					case 3:
						if (mq.matches) {
							return true;
						}
						return false;
					default:
						return true;
				}
			}
		};

		const sliderReset = () => {
			const slider = document.querySelector(".promotions .swiper-container");
			if (slider) {
				slider.swiper.destroy();
				const promotionsSection = new Swiper(".promotions .swiper-container", {
					// Optional parameters
					direction: "horizontal",
					loop: false,
					spaceBetween: 50,
					enabled: checkEnabled(),
					// Responsive
					breakpoints: {
						576: {
							width: 410,
						},
					},

					// Navigation arrows
					navigation: {
						nextEl: ".promotions .js-promotions-button-next",
						prevEl: ".promotions .js-promotions-button-prev",
					},
				});
			}
		};

		const promotionsSection = new Swiper(".promotions .swiper-container", {
			// Optional parameters
			direction: "horizontal",
			loop: false,
			spaceBetween: 50,
			enabled: checkEnabled(),
			// Responsive
			breakpoints: {
				576: {
					width: 410,
				},
			},

			// Navigation arrows
			navigation: {
				nextEl: ".promotions .js-promotions-button-next",
				prevEl: ".promotions .js-promotions-button-prev",
			},
		});
		const promotionsSlider = document.querySelector(
			".promotions .swiper-container"
		);

		if (promotionsSlider) {
			window.addEventListener("resize", sliderReset);
		}
	}

	setAnimationScenes(controller) {
		// Set animation classes to all the elements that will use them.
		let animatableElements = [
			".featured-content",
			".grid__card",
			".promotions",
			".claim",
			".grid",
			".featured-links",
			".gallery",
			".how-to-get-there",
			".activities",
			".booking-info",
			".featured-activities",
			".gift",
			".promo-banner",
			".video",
		];
		for (const el of Array.from(
			document.querySelectorAll(animatableElements.join(","))
		)) {
			el.classList.add("animatable");
			new ScrollMagic.Scene({
				triggerElement: el,
				triggerHook: 0.75,
				reverse: false,
			})
				.setClassToggle(el, "animated")
				.addTo(controller);
		}
	}

	fixWpAdminBar() {
		if (!document.querySelector("#wpadminbar")) {
			return;
		}

		const xmlhttp = ajaxReq(); // eslint-disable-line
		const url = encodeURI(scripts_ajax_variables.ajax_url); // eslint-disable-line
		const params =
			"action=msb_fix_barbajs_wp_admin&location=" + window.location.href;

		xmlhttp.open("POST", url, true); // set true for async, false for sync request
		xmlhttp.setRequestHeader(
			"Content-type",
			"application/x-www-form-urlencoded"
		);
		xmlhttp.send(params); // or null, if no parameters are passed
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
				try {
					var response = JSON.parse(xmlhttp.responseText);
					if (response && response.success === true) {
						const adminMenuLinks = Array.from(
							document.querySelector(".ab-top-menu").querySelectorAll("a")
						);
						for (const adminLink of adminMenuLinks) {
							const mregex = /post=(\d+)&/g;
							adminLink.classList.add("js-barba-prevent");
							const href = adminLink.getAttribute("href");
							const groups = mregex.exec(href);
							if (groups && groups.length >= 2) {
								adminLink.setAttribute(
									"href",
									href.replace(groups[1], response.pageId)
								);
							} else {
								const chunks = href.split("?url=");
								if (chunks.length === 2) {
									adminLink.setAttribute(
										"href",
										chunks[0] +
											"?url=" +
											encodeURIComponent(window.location.href)
									);
								}
							}
						}
					}
				} catch (error) {
					console.log(error);
				}
			}
		};
	}

	budgetForm() {
		if (!window.moment) {
			window.loadJS(
				"/wp-content/themes/mon-st-benet/js/vendor/moment.min.js",
				() => {}
			);
		}
	}
}

export default Barba;
