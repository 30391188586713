import FsLightbox from "fslightbox"; // eslint-disable-line

class VideoCta {
	constructor() {
		this.videoPlayButtons = document.getElementsByClassName("js-video-play");
		if (this.videoPlayButtons[0]) {
			this.init();
		}
	}

	init() {
		refreshFsLightbox(); // eslint-disable-line

		Array.prototype.forEach.call(this.videoPlayButtons, (videoPlayButton) => {
			const lightboxDataAttribute = videoPlayButton.dataset.fslightbox;
			// Autoplay popup video
			window.fsLightboxInstances[lightboxDataAttribute].props.onOpen = (
				FsLightbox // eslint-disable-line
			) => {
				const video = document.querySelectorAll(
					".fslightbox-container video"
				)[0];

				if (video) {
					video.load();
					video.addEventListener("canplaythrough", () => {
						video.play();
					});
				}
			};
		});
	}
}

export default VideoCta;
