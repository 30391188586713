import "./scss/style.scss";
import "./js/lib/polyfills";
import ScrollMagic from "scrollmagic";
import Header from "./js/template-parts/common/header";
import ModulesLoader from "./js/modules-loader";
import Barba from "./js/lib/barba";
import Cookies from "./js/template-parts/popups/cookies";
import DisableScroll from "./js/lib/disable-scroll";

// Disable scroll
window.disableScroll = new DisableScroll();

// Cookies banner
export let cookies = Cookies(); // eslint-disable-line

// Add/remove class if header has preheader
window.checkPreheader = function checkPreheader() {
	const preheader = document.getElementsByClassName("preheader");
	const header = document.getElementById("header");

	if (preheader.length > 0) {
		header.classList.add("header--preheader");
	} else {
		header.classList.remove("header--preheader");
	}
};

// Throttle
window.throttle = function throttle(cb, wait = 100) {
	let timer = null;
	const throttleFunction = (args) => {
		if (timer === null) {
			timer = setTimeout(() => {
				cb.apply(this, args);
				timer = null;
			}, wait);
		}
	};

	return throttleFunction;
};

document.addEventListener("DOMContentLoaded", () => {
	// Header
	window.header = Header();

	// BarbaJS
	window.barba = new Barba();

	// Animations
	window.barba.setAnimationScenes(new ScrollMagic.Controller());
});

window.addEventListener("load", () => {
	// Ajax requests
	window.ajaxReq = function ajaxReq() {
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest(); // eslint-disable-line
		}

		if (window.ActiveXObject) {
			return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line no-console
		}

		console.log("Browser does not support XMLHTTP."); // eslint-disable-line no-console
		return false;
	};

	// Load JS scripts
	window.loadJS = function loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("script");
		// set the type attribute
		jsElm.type = "application/javascript";
		// make the script element load file
		jsElm.src = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Modules loader
	window.googleapi = false;
	window.modulesLoader = new ModulesLoader();
	window.modulesLoader.loadModules();
});
