/* eslint-disable class-methods-use-this */

import LazyLoading from "./lib/lazy-loading";
import Pagination from "./template-parts/components/pagination";
import Hero from "./template-parts/sections/hero";
import Booking from "./template-parts/components/booking";
import Filters from "./template-parts/components/filters";
import ContactForm from "./template-parts/components/contact-form";
import CompanyForm from "./template-parts/components/company-form";
import GroupForm from "./template-parts/components/group-form";
import WeddingForm from "./template-parts/components/wedding-form";
import WorkUsForm from "./template-parts/components/work-us-form";
import SchoolForm from "./template-parts/components/school-form";
import Newsletter from "./template-parts/components/newsletter";
import VideoCta from "./template-parts/components/video-cta";
import Cards from "./template-parts/sections/cards";
import Map from "./template-parts/sections/map";
import Gallery from "./template-parts/sections/gallery";
import Slider from "./template-parts/components/slider";

window.masonry = undefined;

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		window.lazyLoading = new LazyLoading();
		window.hero = new Hero();
		window.booking = new Booking();
		window.pagination = new Pagination();
		window.filters = new Filters();
		window.contactForm = new ContactForm();
		window.companyForm = new CompanyForm();
		window.weddingForm = new WeddingForm();
		window.groupForm = new GroupForm();
		window.workusForm = new WorkUsForm();
		window.schoolForm = new SchoolForm();
		window.newsletter = new Newsletter();
		window.videoCta = new VideoCta();
		window.map = new Map();
		window.cards = new Cards();
		window.gallery = new Gallery();
		window.slider = new Slider();
	}
}
