/* eslint-disable */
class Map {
	constructor() {
		const mapContainers = document.getElementsByClassName("map");

		if (mapContainers.length > 0) {
			if (window.googleapi === false) {
				const apiKey = mapContainers[0].dataset.api;

				window.loadJS(
					"https://maps.googleapis.com/maps/api/js?key=" + apiKey,
					() => {
						Array.from(mapContainers).forEach((mapContainer) => {
							this.latitude = mapContainer.dataset.latitude;
							this.longitude = mapContainer.dataset.longitude;
							this.zoom = parseInt(mapContainer.dataset.zoom);
							this.title = mapContainer.dataset.title;
							this.id = mapContainer.dataset.id;

							this.init(
								this.latitude,
								this.longitude,
								this.zoom,
								this.title,
								this.id
							);
						});
					}
				);
				window.googleapi = true;
			}
		}
	}

	init(latitude, longitude, zoom, title, id) {
		let directionsDisplay =
			new google.maps.DirectionsRenderer(); /* eslint-disable-line */
		let height = new google.maps.LatLng(
			latitude,
			longitude
		); /* eslint-disable-line */
		const mapOptions = {
			zoom: zoom,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			center: height,
		};
		let map = new google.maps.Map(
			document.getElementById(id),
			mapOptions
		); /* eslint-disable-line */
		const marker = new google.maps.Marker({
			/* eslint-disable-line */ position: height,
			map: map,
			title: title,
		});

		directionsDisplay.setMap(map);

		// please note,
		// that IE11 now returns undefined again for window.chrome
		// and new Opera 30 outputs true for window.chrome
		// and new IE Edge outputs to true now for window.chrome
		// and if not iOS Chrome check
		// so use the below updated condition
		const isChromium = window.chrome;
		const winNav = window.navigator;
		const vendorName = winNav.vendor;
		const isOpera = winNav.userAgent.indexOf("OPR") > -1;
		const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
		const isIOSChrome = winNav.userAgent.match("CriOS");

		if (isIOSChrome) {
			// is Google Chrome on IOS
		} else if (
			(isChromium !== null &&
				isChromium !== undefined &&
				vendorName === "Google Inc." &&
				isOpera === false &&
				isIEedge === false) ||
			navigator.userAgent.indexOf("Safari") !== -1
		) {
			// is Google Chrome or Safari
			document.addEventListener("DOMContentLoaded", function (event) {
				document.getElementById("panel").style.display = "none";
			});
		} else {
			// not Google Chrome
		}
	}
}

export default Map;
