/* eslint-disable */
class Gallery {
	constructor() {
		this.galleryContainer = document.getElementsByClassName("gallery")[0];
		if (this.galleryContainer !== undefined) {
			if (window.basicScroll === undefined) {
				window.loadJS(
					"/wp-content/themes/mon-st-benet/js/vendor/basicScroll.min.js",
					() => {
						this.init();
					}
				);
			} else {
				setTimeout(() => {
					this.init();
				}, 1000);
			}
		}
	}

	init() {
		document.querySelectorAll(".gallery__image").forEach((elem) => {
			const rect = elem.getBoundingClientRect();
			const modifier = elem.getAttribute("data-modifier");
			const from = rect["y"] - Math.abs(modifier * 4);
			const to = rect["y"] + Math.abs(modifier * 4);

			basicScroll
				.create({
					elem: elem,
					from: from,
					to: to,
					direct: true,
					props: {
						"--translateY": {
							from: "0",
							to: `${modifier}px`,
						},
					},
				})
				.start();
		});
	}
}

export default Gallery;
